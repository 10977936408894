import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import PublicLayout from '@/layouts/Public'
import MainLayout from '@/layouts/Main/index-v2'
import PassThrough from '@/layouts/PassThrough'
// import AdminLayout from '@/layouts/Admin'
import store from '@/store'
import { i18n } from '@/i18n'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // initial admin
    // {
    //   path: '/dashboard/admin',
    //   component: AdminLayout,
    //   name: 'dashboard.admin',
    //   meta: {
    //     authRequired: true,
    //     title: 'Powerbiz - Dashboard Admin',
    //   },
    // },
    {
      path: '/',
      component: MainLayout,
      meta: {
        authRequired: true,
        title: 'PowerBiz',
        hidden: true,
      },
      children: [
        // DASHBOARD
        {
          path: '/dashboard',
          meta: {
            title: `Powerbiz - ${i18n.t('sideBar.dashboard')}`,
            authRequired: true,
            key: 'dashboard', // NOTE: please use key here. value based on key link side left menu list
          },
          component: () => import('./views/dashboard/index-v2.vue'),
        },
        {
          path: '/dashboardv2',
          meta: {
            title: `Powerbiz - ${i18n.t('sideBar.dashboard')} v2`,
            authRequired: true,
            key: 'dashboardv2', // NOTE: please use key here. value based on key link side left menu list
          },
          component: () => import('./views/dashboard/index-v2.vue'),
        },

        // SALES
        {
          path: 'sales',
          component: PassThrough,
          name: 'sales',
          children: [
            {
              path: '/sales',
              name: 'sales.index',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.sales')}`,
                authRequired: true,
                key: 'sales', // * NOTE: please use key here. value based on key link side left menu list
              },
              component: () => import('@/views/sales/index.vue'),
            },
          ],
        },

        // FULFILLMENT
        {
          path: 'fulfillment',
          component: PassThrough,
          name: 'fulfillment',
          children: [
            {
              path: '/fulfillment',
              name: 'fulfillment.index',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.fulfillment')}`,
                key: 'fulfillment', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/fulfillment/index.vue'),
              children: [
                {
                  path: '/fulfillment/detail',
                  name: 'fulfillment-details',
                  meta: {
                    title: `Powerbiz - ${i18n.t('sideBar.fulfillment')}`,
                    key: 'fulfillment-detail', // * NOTE: please use key here. value based on key link side left menu list
                    authRequired: true,
                  },
                  component: () =>
                    import('@/components/Fulfillment/Detail/index.vue'),
                  children: [
                    {
                      path: '/fulfillment/detail/update-order',
                      name: 'fulfilment-edit-order',
                      meta: {
                        title: `Powerbiz - ${i18n.t('sideBar.fulfillment')}`,
                        authRequired: true,
                        key: 'fulfillment-detail-update-order', // * NOTE: please use key here. value based on key link side left menu list
                      },
                      component: () =>
                        import('@/components/Fulfillment/UpdateOrder'),
                    },
                  ],
                },
              ],
            },
            {
              path: '/fulfillment/export',
              name: 'fulfilment-export',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.fulfillment')}`,
                key: 'fulfillment-export', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/components/Fulfillment/Export'),
            },
            {
              path: '/fulfillment/label',
              name: 'fulfillment-label',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.fulfillment')}`,
                key: 'fulfillment-label', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () =>
                import('@/components/Fulfillment/PrintLabel/index.vue'),
            },
          ],
        },

        // DELIVERY ORDER
        {
          path: 'delivery-order',
          component: PassThrough,
          name: 'delivery-order',
          children: [
            {
              path: '/delivery-order',
              name: 'delivery-order.index',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.fulfillment')}`,
                key: 'delivery-order', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/delivery-order/index.vue'),
              children: [
                {
                  path: '/delivery-order/detail',
                  name: 'delivery-order-details',
                  meta: {
                    title: `Powerbiz - ${i18n.t('sideBar.fulfillment')}`,
                    key: 'delivery-order-detail', // * NOTE: please use key here. value based on key link side left menu list
                    authRequired: true,
                  },
                  component: () =>
                    import('@/components/DeliveryOrder/Detail/index.vue'),
                  children: [
                    {
                      path: '/delivery-order/detail/update-order',
                      name: 'delivery-order-edit-order',
                      meta: {
                        title: `Powerbiz - ${i18n.t('sideBar.fulfillment')}`,
                        authRequired: true,
                        key: 'delivery-order-detail-update-order', // * NOTE: please use key here. value based on key link side left menu list
                      },
                      component: () =>
                        import('@/components/DeliveryOrder/UpdateOrder'),
                    },
                  ],
                },
              ],
            },
            {
              path: '/delivery-order/export',
              name: 'delivery-order-export',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.fulfillment')}`,
                key: 'delivery-order-export', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/components/DeliveryOrder/Export'),
            },
            {
              path: '/delivery-order/label',
              name: 'delivery-order-label',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.fulfillment')}`,
                key: 'delivery-order-label', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () =>
                import('@/components/DeliveryOrder/PrintLabel/index.vue'),
            },
          ],
        },

        // Sales Channel
        {
          path: 'sales-channel',
          component: PassThrough,
          name: 'sales-channel',
          children: [
            // {
            //   path: '/sales-channel',
            //   name: 'sales.channel',
            //   meta: {
            //     title: 'Powerbiz - Sales Channel',
            //     authRequired: true,
            //     key: 'sales-channel', // * NOTE: please use key here. value based on key link side left menu list
            //   },
            //   component: () => import('@/views/sales/index.vue'), // @/views/channels/sales-channel/index.vue
            // },
            {
              path: ':id',
              name: 'sales-channel.id',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.sales')} Channel`,
                authRequired: true,
                key: 'sales-channel-:id', // * NOTE: please use key here. value based on key link side left menu list
              },
              component: () => import('@/views/sales/index.vue'), // @/views/channels/sales-channel/index.vue
            },
          ],
        },

        {
          path: 'manual-order',
          component: PassThrough,
          name: 'manual-order',
          children: [
            // {
            //   path: '/manual-order',
            //   name: 'sales.channel',
            //   meta: {
            //     title: 'Powerbiz - Sales Channel',
            //     authRequired: true,
            //     key: 'manual-order', // * NOTE: please use key here. value based on key link side left menu list
            //   },
            //   component: () => import('@/views/sales/index.vue'), // @/views/channels/manual-order/index.vue
            // },
            {
              path: ':id',
              name: 'manual-order.id',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.sales')} Channel`,
                authRequired: true,
                key: 'manual-order-:id', // * NOTE: please use key here. value based on key link side left menu list
              },
              component: () => import('@/views/manual-order/index.vue'), // @/views/channels/manual-order/index.vue
            },
            {
              path: ':id/create',
              name: 'sales.create',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.sales')}`,
                authRequired: true,
                key: 'sales', // * NOTE: please use key here. value based on key link side left menu list
              },
              component: () => import('@/views/manual-order/create.vue'),
            },
          ],
        },

        // Sales Channel Kino
        {
          path: 'sales-channel-kino',
          component: PassThrough,
          name: 'sales-channel-kino',
          children: [
            {
              path: ':id',
              name: 'sales-channel-kino.id',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.sales')} Channel Kino`,
                authRequired: true,
                key: 'sales-channel-kino:id', // * NOTE: please use key here. value based on key link side left menu list
              },
              component: () => import('@/views/sales/index.vue'),
            },
          ],
        },

        /* === Web Platform Channel === */
        {
          path: 'channel/store',
          component: PassThrough,
          children: [
            {
              path: ':channelId',
              component: PassThrough,
              children: [
                {
                  path: 'product',
                  component: PassThrough,
                  children: [
                    {
                      path: '',
                      name: 'channel.store.product.index',
                      component: () => import('@/views/web-platform-channel/product/index.vue'),
                      meta: {
                        authRequired: true,
                        key: 'web-platform-product-channel-:id', // * NOTE: please use key here. value based on key link side left menu list
                      },
                    },
                    {
                      path: 'add',
                      name: 'channel.store.product.add',
                      component: () => import('@/views/web-platform-channel/product/add.vue'),
                    },
                    {
                      path: 'collection/:type?',
                      name: 'product-channel.product-collection',
                      component: () => import('@/views/web-platform-channel/product/collection/index.vue'),
                    },
                    {
                      path: 'collection/:type?',
                      name: 'channel.store.product.collection',
                      component: () => import('@/views/web-platform-channel/product/collection/index.vue'),
                    },
                    {
                      path: ':id',
                      name: 'channel.store.product.edit',
                      component: () => import('@/views/web-platform-channel/product/[id]/edit.vue'),
                    },
                  ],
                },
              ],
            },
          ],
        },

        // Product
        {
          path: 'web-platform-product',
          component: PassThrough,
          name: 'web-platform-product-channel.index',
          children: [
            {
              path: ':id',
              name: 'web-platform-product-channel.product-master',
              redirect: (to) => {
                const { params, query } = to

                return {
                  name: 'channel.store.product.index',
                  query,
                  params: {
                    channelId: params.id,
                  },
                }
              },
            },
            {
              path: ':id/add-product',
              name: 'web-platform-product-channel.add-product',
              meta: {
                title: `Powerbiz - ${i18n.t('product.addProduct')} Channel`,
                authRequired: true,
                key: 'web-platform-product-channel-:id', // * NOTE: please use key here. value based on key link side left menu list
              },
              component: () =>
                import(
                  '@/views/web-platform-channel/products/add.vue'
                ),
            },
            {
              path: ':id/edit-product/:productId',
              name: 'web-platform-product-channel.edit-product',
              meta: {
                title: `Powerbiz - ${i18n.t('product.editProduct')} Channel`,
                authRequired: true,
                key: 'web-platform-product-channel-:id', // * NOTE: please use key here. value based on key link side left menu list
              },
              component: () =>
                import(
                  '@/views/web-platform-channel/products/edit.vue'
                ), 
            },
          ],
        },
        // Reseller
        {
          path: 'web-affiliate',
          component: PassThrough,
          name: 'web-affiliate.index',
          children: [
            {
              path: ':id',
              name: 'web-affiliate.id',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.reseller')} Channel`,
                authRequired: true,
                key: 'web-affiliate-:id', // * NOTE: please use key here. value based on key link side left menu list
              },
              component: () =>
                import('@/views/web-platform-channel/reseller/index.vue'), // @/views/web-platform-channel/reseller/index.vue
              children: [
                {
                  path: 'detail',
                  name: 'web-affiliate.detail',
                  meta: {
                    title: `Powerbiz - ${i18n.t(
                      'sideBar.resellerdetail',
                    )} Channel`,
                    key: 'web-affiliate-:id', // * NOTE: please use key here. value based on key link side left menu list
                  },
                  component: () =>
                    import('@/views/web-platform-channel/reseller/detail'),
                },
              ],
            },
          ],
        },
        // Promotion / Discount
        {
          name: 'web-voucher.index',
          path: 'web-voucher',
          component: PassThrough,
          children: [
            {
              name: 'promotion-channel.web-voucher',
              path: ':id',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.voucher')} Channel`,
                authRequired: true,
                key: 'web-voucher-:id', // * NOTE: please use key here. value based on key link side left menu list
              },
              component: () =>
                import('@/views/web-platform-channel/promotion/voucher'),
              children: [
                {
                  path: 'detail-voucher',
                  name: 'discounts.view',
                  meta: {
                    title: `Powerbiz - ${i18n.t(
                      'sideBar.voucherdetail',
                    )} Channel`,
                    authRequired: true,
                    key: 'web-voucher-:id', // * NOTE: please use key here. value based on key link side left menu list
                  },
                  component: () =>
                    import(
                      '@/views/web-platform-channel/promotion/voucher/detail'
                    ),
                },
              ],
            },
            {
              path: ':id/add-voucher',
              name: 'add-voucher',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.addvoucher')} Channel`,
                authRequired: true,
                key: 'web-voucher-:id', // * NOTE: please use key here. value based on key link side left menu list
              },
              component: () =>
                import('@/views/web-platform-channel/promotion/voucher/create'),
            },
            {
              path: ':id/edit-voucher',
              name: 'edit-voucher',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.editvoucher')} Channel`,
                authRequired: true,
                key: 'web-voucher-:id', // * NOTE: please use key here. value based on key link side left menu list
              },
              component: () =>
                import('@/views/web-platform-channel/promotion/voucher/edit'),
            },
          ],
        },
        // Store settings
        {
          name: 'web-store-setting',
          path: 'web-store-setting',
          component: PassThrough,
          children: [
            {
              name: 'web-store-setting.id',
              path: ':id',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.storesetting')} Channel`,
                authRequired: true,
                key: 'web-store-setting-:id', // * NOTE: please use key here. value based on key link side left menu list
              },
              component: () =>
                import('@/views/web-platform-channel/store-settings'),
            },
          ],
        },
        /* === End Web Platform Channel === */

        /* Callback integration marketplace */
        {
          path: 'integration',
          component: PassThrough,
          name: 'integration',
          children: [
            {
              path: '/integration/tiktok',
              name: 'integration.tiktok',
              redirect: to => {
                if (to.query.code) {
                  const pathCallback = JSON.parse(
                    localStorage.getItem('pathCallback'),
                  )
                  pathCallback.query.code = to.query.code
                  localStorage.setItem(
                    'pathCallback',
                    JSON.stringify(pathCallback),
                  )
                }
                if (typeof localStorage.getItem('pathCallback') === 'string') {
                  return {
                    ...JSON.parse(localStorage.getItem('pathCallback')),
                    query: {
                      ...JSON.parse(localStorage.getItem('pathCallback')).query,
                      code: to.query.code,
                    },
                  }
                } else {
                  return '/dashboard'
                }
              },
            },
            {
              path: '/integration/shopee',
              name: 'integration.shopee',
              redirect: to => {
                if (to.query.code) {
                  const pathCallback = JSON.parse(
                    localStorage.getItem('pathCallback'),
                  )
                  pathCallback.query.code = to.query.code
                  pathCallback.query.shop_id = to.query.shop_id
                  localStorage.setItem(
                    'pathCallback',
                    JSON.stringify(pathCallback),
                  )
                }
                if (typeof localStorage.getItem('pathCallback') === 'string') {
                  return {
                    ...JSON.parse(localStorage.getItem('pathCallback')),
                    query: {
                      ...JSON.parse(localStorage.getItem('pathCallback')).query,
                      code: to.query.code,
                      shop_id: to.query.shop_id,
                    },
                  }
                } else {
                  return '/dashboard'
                }
              },
            },
            {
              path: '/integration/lazada/callback',
              name: 'integration.lazada',
              redirect: to => {
                if (to.query.code) {
                  const pathCallback = JSON.parse(
                    localStorage.getItem('pathCallback'),
                  )
                  pathCallback.query.code = to.query.code
                  localStorage.setItem(
                    'pathCallback',
                    JSON.stringify(pathCallback),
                  )
                }
                if (typeof localStorage.getItem('pathCallback') === 'string') {
                  return {
                    ...JSON.parse(localStorage.getItem('pathCallback')),
                    query: {
                      ...JSON.parse(localStorage.getItem('pathCallback')).query,
                      code: to.query.code,
                    },
                  }
                } else {
                  return '/dashboard'
                }
              },
            },
          ],
        },

        // PRODUCT
        {
          path: 'product',
          component: PassThrough,
          name: 'product',
          children: [
            {
              path: '/product',
              name: 'product.master',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.product_master')}`,
                key: 'product-master',
                authRequired: true,
              },
              component: () => import('@/views/products/index-v2.vue'),
            },
            {
              path: '/product-category',
              name: 'product.category',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.productCategory')}`,
                key: 'product-category',
                authRequired: true,
              },
              component: () => import('@/views/category/index.vue'),
            },
            {
              path: '/product/create',
              name: 'product.create',
              meta: {
                title: 'Powerbiz - Add Product Master',
                key: 'create-product',
                authRequired: true,
              },
              component: () => import('@/views/products/create'),
            },
            {
              path: '/product/edit',
              name: 'product.edit',
              meta: {
                title: 'Powerbiz - Edit Product Master',
                key: 'edit-product',
              },
              component: () => import('@/views/products/edit'),
            },
            {
              path: '/product/import',
              name: 'product.import',
              meta: {
                title: 'Powerbiz - Import Product Master',
                key: 'import-product',
              },
              component: () => import('@/views/products/import'),
            },
            {
              path: '/product/allocation',
              name: 'product.allocation',
              meta: {
                title: 'Powerbiz - Stock Allocation',
                key: 'allocation-product',
              },
              component: () => import('@/views/products/allocation'),
            },
            {
              path: '/product/bulk-publish',
              name: 'product.bulk-publish',
              meta: {
                title: 'Powerbiz - Bulk Publish Product Channel',
                key: 'import-product',
              },
              component: () => import('@/views/products/bulk-publish'),
            },
          ],
        },

        // PRODUCT CHANNEL
        {
          path: '/product-channel',
          component: PassThrough,
          name: 'product-channel',
          children: [
            {
              path: ':id',
              component: () => import('@/views/store/[id].vue'),
              children: [
                {
                  path: 'product/synchronize',
                  name: 'product-channel.product-list',
                  meta: {
                    title: `Powerbiz - ${i18n.t('sideBar.product_list')}`,
                    key: 'product-list-:id', // NOTE: please use key here. value based on key link side left menu list
                    authRequired: true,
                  },
                  component: () =>
                    import('@/views/store/[id]/product/synchronize/index.vue'),
                },
                {
                  path: 'product/synchronize/create-product-tokopedia',
                  name: 'product.channel.tokopedia_id',
                  meta: {
                    title: 'Powerbiz - Add Product Tokopedia',
                    key: 'create-product-tokopedia',
                    authRequired: true,
                  },
                  component: () =>
                    import(
                      '@/views/store/[id]/product/synchronize/tokopedia/create.vue'
                    ),
                },
                {
                  path: 'product/synchronize/create-product-tiktok',
                  name: 'product.channel.create',
                  meta: {
                    title: 'Powerbiz - Add Product Channel',
                    key: 'create-product-channel',
                    authRequired: true,
                  },
                  component: () =>
                    import(
                      '@/views/store/[id]/product/synchronize/tiktok/create.vue'
                    ),
                },
                {
                  path: 'product/synchronize/create-product-shopee',
                  name: 'product.channel.shopee_id',
                  meta: {
                    title: 'Powerbiz - Add Product Channel',
                    key: 'create-product-channel-shopee',
                    authRequired: true,
                  },
                  component: () =>
                    import(
                      '@/views/store/[id]/product/synchronize/shopee/create.vue'
                    ),
                },
                {
                  path: 'product/synchronize/create-product-blibli',
                  name: 'product.channel.blibli_id',
                  meta: {
                    title: 'Powerbiz - Add Product Channel',
                    key: 'create-product-channel-blibli',
                    authRequired: true,
                  },
                  component: () =>
                    import(
                      '@/views/store/[id]/product/synchronize/blibli/create.vue'
                    ),
                },
                {
                  path: 'product/synchronize/create-product-distributor',
                  name: 'product.channel.distributor',
                  meta: {
                    title: 'Powerbiz - Add Product Channel',
                    key: 'create-product-channel',
                    authRequired: true,
                  },
                  component: () =>
                    import(
                      '@/views/store/[id]/product/synchronize/distributor/create.vue'
                    ),
                },
                {
                  path: 'product/edit-product-tiktok',
                  name: 'product-channel.product-synchronize-edit',
                  meta: {
                    title: `Powerbiz - ${i18n.t('sideBar.product_list')}`,
                    key: 'product-list-:id', // NOTE: please use key here. value based on key link side left menu list
                    authRequired: true,
                  },
                  component: () =>
                    import(
                      '@/views/store/[id]/product/synchronize/tiktok/edit.vue'
                    ),
                },
                {
                  path: 'product/edit-product-tokopedia',
                  name: 'product-channel.product-synchronize-edit-tokped',
                  meta: {
                    title: `Powerbiz - ${i18n.t('sideBar.product_list')}`,
                    key: 'product-list-:id', // NOTE: please use key here. value based on key link side left menu list
                    authRequired: true,
                  },
                  component: () =>
                    import(
                      '@/views/store/[id]/product/synchronize/tokopedia/edit.vue'
                    ),
                },
                {
                  path: 'product/edit-product-shopee',
                  name: 'product-channel.product-synchronize-edit-shopee',
                  meta: {
                    title: `Powerbiz - ${i18n.t('sideBar.product_list')}`,
                    key: 'product-list-:id', // NOTE: please use key here. value based on key link side left menu list
                    authRequired: true,
                  },
                  component: () =>
                    import(
                      '@/views/store/[id]/product/synchronize/shopee/edit.vue'
                    ),
                },
                {
                  path: 'product/edit-product-blibli',
                  name: 'product-channel.product-synchronize-edit-blibli',
                  meta: {
                    title: `Powerbiz - ${i18n.t('sideBar.product_list')}`,
                    key: 'product-list-:id', // NOTE: please use key here. value based on key link side left menu list
                    authRequired: true,
                  },
                  component: () =>
                    import(
                      '@/views/store/[id]/product/synchronize/blibli/edit.vue'
                    ),
                },
                {
                  path: 'product/edit-product-lazada',
                  name: 'product-channel.product-synchronize-edit-lazada',
                  meta: {
                    title: `Powerbiz - ${i18n.t('sideBar.product_list')}`,
                    key: 'product-list-:id', // NOTE: please use key here. value based on key link side left menu list
                    authRequired: true,
                  },
                  component: () =>
                    import(
                      '@/views/store/[id]/product/synchronize/lazada/edit.vue'
                    ),
                },
                {
                  path: 'product/edit-product-distributor',
                  name: 'product-channel.distributor-product-synchronize-edit',
                  meta: {
                    title: `Powerbiz - ${i18n.t('sideBar.product_list')}`,
                    key: 'product-list-:id', // NOTE: please use key here. value based on key link side left menu list
                    authRequired: true,
                  },
                  component: () =>
                    import(
                      '@/views/store/[id]/product/synchronize/distributor/edit.vue'
                    ),
                },
                {
                  path: 'price-area',
                  name: 'product-channel.price-area',
                  meta: {
                    title: `Powerbiz - ${i18n.t('sideBar.product_list')}`,
                    key: 'price-area-:id', // NOTE: please use key here. value based on key link side left menu list
                    authRequired: true,
                  },
                  component: () => import('@/views/store/price-area/index.vue'),
                },
              ],
            },
          ],
        },

        // PRICE SETTINGS
        {
          path: 'price',
          component: PassThrough,
          name: 'price',
          children: [
            {
              path: '/price',
              name: 'price-setting',
              meta: {
                title: 'Powerbiz - Price Setting',
                key: 'price-setting',
                authRequired: true,
              },
              component: () => import('@/views/price/formula-variable'),
            },
            {
              path: '/formula-variable',
              name: 'formula variable',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.formula_variable')}`,
                key: 'formula-variable',
                authRequired: true,
              },
              component: () => import('@/views/price/formula-variable'),
            },
            {
              path: '/formula-variable/add',
              name: 'formula variable.add',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.formula_variable')}`,
                key: 'add-formula-variable',
                authRequired: true,
              },
              component: () => import('@/views/price/formula-variable/add'),
            },
            {
              path: '/formula-variable/edit/:id',
              name: 'formula variable.edit',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.formula_variable')}`,
                key: 'edit-formula-variable',
                authRequired: true,
              },
              component: () => import('@/views/price/formula-variable/edit'),
            },
            {
              path: '/global-variable',
              name: 'global variable',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.global_variable')}`,
                key: 'global-variable',
                authRequired: true,
              },
              component: () => import('@/views/price/global-variable'),
            },
            {
              path: '/global-variable/add',
              name: 'global variable.add',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.global_variable')}`,
                key: 'add-global-variable',
                authRequired: true,
              },
              component: () => import('@/views/price/global-variable/add'),
            },
            {
              path: '/global-variable/edit/:id',
              name: 'global variable.edit',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.global_variable')}`,
                key: 'edit-global-variable',
                authRequired: true,
              },
              component: () => import('@/views/price/global-variable/edit'),
            },
            {
              path: '/price-formula',
              name: 'price formula',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.price_formula')}`,
                key: 'price-formula',
                authRequired: true,
              },
              component: () => import('@/views/price/price-formula'),
            },
            {
              path: '/price-formula/add',
              name: 'price-formula.add',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.price_formula')}`,
                key: 'add-price-formula',
                authRequired: true,
              },
              component: () => import('@/views/price/price-formula/add'),
            },
            {
              path: '/price-formula/edit/:id',
              name: 'price-formula.edit',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.price_formula')}`,
                key: 'edit-price-formula',
                authRequired: true,
              },
              component: () => import('@/views/price/price-formula/edit'),
            },
            {
              path: '/product-variable',
              name: 'product variable',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.product_variable')}`,
                key: 'product-variable',
                authRequired: true,
              },
              component: () => import('@/views/price/product-variable'),
            },
            {
              path: '/product-variable/add',
              name: 'product variable.add',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.product_variable')}`,
                key: 'add-product-variable',
                authRequired: true,
              },
              component: () => import('@/views/price/product-variable/add'),
            },
            {
              path: '/product-variable/edit/:id',
              name: 'product variable.edit',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.product_variable')}`,
                key: 'edit-product-variable',
                authRequired: true,
              },
              component: () => import('@/views/price/product-variable/edit'),
            },
          ],
        },

        // INVENTORY
        {
          path: '',
          component: PassThrough,
          name: 'inventory',
          children: [
            {
              path: '/inventory/stock-alocation',
              name: 'inventory.stock.alocation',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.inventory_list')}`,
                key: 'stock-alocation', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/inventory/productavailability'),
            },
            {
              path: '/inventory/goods-receipt',
              name: 'inventory.goods.receipt',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.inventory_list')}`,
                key: 'goods-receipt', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/inventory/goods-receipt'),
            },
            {
              path: '/inventory/goods-receipt/create',
              name: 'inventory.goods.receipt.create',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.inventory_list')}`,
                key: 'goods-receipt-create', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/inventory/goods-receipt/create'),
            },
            {
              path: '/inventory/stock-adjustment',
              name: 'inventory.stock.adjustment',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.inventory_list')}`,
                key: 'stock-adjustment', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/inventory/stockAdjustment'),
            },
            {
              path: '/inventory/stock-adjustment/create',
              name: 'inventory.stock.adjustment.create',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.inventory_list')}`,
                key: 'stock-adjustment-create', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/inventory/stockAdjustment/create/create.vue'),
            },
            {
              path: '/inventory/list-inventoryv2',
              name: 'inventory.list.inventory.v2',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.inventory_list')}`,
                key: 'list-inventoryv2', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/inventory/inventory-v2'),
            },
            {
              path: '/inventory/detail-stock-movement/:id',
              name: 'inventory.detail.stock.movement',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.inventory_movement')}`,
                key: 'list-inventoryv2', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/inventory/inventory-v2/detail-stock-movement'),
            },
            {
              path: '/inventory/inbound',
              name: 'inventory.inbound',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.inventory_list')}`,
                key: 'inbound', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/inventory/inbound'),
            },
            {
              path: '/inventory/stock-movement',
              name: 'inventory.stock.movement',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.inventory_list')}`,
                key: 'inventory-stock-movement', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/inventory/stock-movement'),
            },
          ],
        },

        // Finance
        {
          path: '/finance-channel',
          component: PassThrough,
          name: 'finance-channel',
          children: [
            {
              path: ':id/withdrawal',
              name: 'finance-channel.withdrawal',
              meta: {
                title: `Powerbiz - Withdrawal`,
                key: 'withdrawal-:id', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/finance/withdrawl/index.vue'),
            },
            {
              path: ':id/wallet/history',
              name: 'finance-channel.wallet.history',
              meta: {
                title: `Powerbiz - Wallet History`,
                key: 'withdrawal-:id', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/finance/history-wallet/index.vue'),
            },
            {
              path: ':id/wallet-withdrawl',
              name: 'distributor-channel.wallet-withdrawl',
              meta: {
                title: 'Powerbiz - wallet withdrawl',
                key: 'withdrawal-:id',
                authRequired: true,
              },
              component: () =>
                import('@/views/finance/withdrawl/walletWithdrawl'),
            },
            {
              path: ':id/payment-channel',
              name: 'finance-channel.payment-channel',
              meta: {
                title: `Powerbiz - Payment`,
                key: 'payment-channel-:id', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () =>
                import('@/views/finance/payment-channel/index.vue'),
            },
            {
              path: ':id/payment-history',
              name: 'finance-channel.payment-history',
              meta: {
                title: `Powerbiz - Payment History`,
                key: 'payment-history-:id', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () =>
                import('@/views/finance/payment-history/index.vue'),
            },
            {
              path: ':id/invoice',
              name: 'finance-channel.invoice',
              meta: {
                title: `Powerbiz - Invoice`,
                key: 'invoice-:id', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () =>
                import('@/views/finance/invoice-channel/index.vue'),
            },
            {
              path: ':id/invoice-detail',
              name: 'finance-channel.invoice-detail',
              meta: {
                title: `Powerbiz - Invoice`,
                key: 'invoice-:id', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () =>
                import(
                  '@/views/finance/invoice-channel/partials/DetailInvoice.vue'
                ),
            },
            {
              path: ':id/credit-notes',
              name: 'finance-channel.credit-notes',
              meta: {
                title: `Powerbiz - Credit Notes`,
                key: 'credit-notes-:id', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/finance/credit-notes/index.vue'),
            },
          ],
        },

        // promotion
        {
          path: '/promotion-channel',
          component: PassThrough,
          name: 'promotion-channel',
          children: [
            {
              path: ':id/discount',
              name: 'promotion-channel.discount',
              meta: {
                title: 'Powerbiz - Product Discount',
                key: 'discount-:id', // NOTE: please use key here. value based on key link side left menu list-
                authRequired: true,
              },
              component: () => import('@/views/promotion/product-discount'),
            },
            {
              path: ':id/discount/add',
              name: 'promotion-channel.discount.add',
              meta: {
                title: 'Powerbiz - Add Discount',
                key: 'discount-:id', // NOTE: please use key here. value based on key link side left menu list-
                authRequired: true,
              },
              component: () =>
                import('@/views/promotion/product-discount/create'),
            },
            {
              path: ':id/voucher',
              name: 'promotion-channel.voucher',
              meta: {
                title: 'Powerbiz - Voucher',
                key: 'voucher-:id', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/promotion/voucher/index.vue'),
            },
            {
              path: ':id/voucher/add',
              name: 'promotion-channel.voucher.add',
              meta: {
                title: 'Powerbiz - Add Voucher',
                key: 'voucher-:id', // NOTE: please use key here. value based on key link side left menu list-
                authRequired: true,
              },
              component: () => import('@/views/promotion/voucher/create.vue'),
            },
            {
              path: ':id/approval',
              name: 'promotion-channel.approval',
              meta: {
                title: 'Powerbiz - Approval',
                key: 'approval-:id', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/promotion/approval'),
            },
          ],
        },

        // PELANGGAN
        {
          path: '/customer-channel',
          component: PassThrough,
          name: 'customer-channel',
          children: [
            {
              path: ':id/customer-list',
              name: 'customer-channel.customer-list',
              meta: {
                title: 'Powerbiz - Customer',
                key: 'customer-list-:id', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/customer-channel/index.vue'),
            },
            {
              path: ':id/customer-list/form',
              name: 'customer-channel.customer-list.form',
              meta: {
                title: 'Powerbiz - Form Customer',
                key: 'customer-list-:id', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/components/InviteCustomer/index.vue'),
            },
            {
              path: ':id/customer-list-v2',
              name: 'customer-channel.customer-list-v2',
              meta: {
                title: 'Powerbiz - Customer',
                key: 'customer-list-v2-:id', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/customer-channel/index.vue'),
            },
            {
              path: ':id/customer-list-v2/form',
              name: 'customer-channel.customer-list-v2.form',
              meta: {
                title: 'Powerbiz - Form Customer',
                key: 'customer-list-v2-:id', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/components/InviteCustomerV2/index.vue'),
            },
            {
              path: ':id/customer-category',
              name: 'customer-channel.customer-category',
              meta: {
                title: 'Powerbiz - Customer',
                key: 'customer-category-:id', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/customer-channel/customer-category/index.vue'),
            },
          ],
        },

        // USER MANAGEMENT
        {
          path: '/user-management',
          component: PassThrough,
          name: 'User Management',
          children: [
            {
              path: '/user-management',
              name: 'user-management',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.user')}`,
                key: 'user-management', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () =>
                import('@/views/setting/userManagement/index.vue'),
            },
            {
              path: '/user-management/invite-user',
              name: 'invite-user',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.user')}`,
                key: 'invite-user',
                authRequired: true,
              },
              component: () => import('@/components/InviteUser/index.vue'),
            },
            {
              path: '/user-management/edit-user',
              name: 'edit-user',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.user')}`,
                key: 'edit-user',
                authRequired: true,
              },
              component: () => import('@/components/InviteUser/index.vue'),
            },
          ],
        },

        // Daftar Toko
        {
          path: '/store-list',
          component: PassThrough,
          // name: 'store-list-home',
          children: [
            {
              path: '/',
              name: 'store-list',
              meta: {
                title: 'Powerbiz - Store List',
                key: 'store-list', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              redirect: {
                name: 'store.list', // Redirect to new route
              },
            },
          ],
        },

        // Add Store
        {
          path: '/add-store',
          component: PassThrough,
          // name: 'add-store',
          children: [
            {
              path: '/',
              name: 'add-store',
              meta: {
                title: 'Powerbiz - Add Store',
                key: 'add-store', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/store/add-store/index.vue'),
            },
          ],
        },

        // NEW CHANNEL
        {
          path: '/store',
          name: 'store',
          component: PassThrough,
          children: [
            {
              path: '/store',
              name: 'store.list',
              meta: {
                title: 'Powerbiz - Store List',
                key: 'store-list', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/store/index.vue'),
            },
            {
              path: '/admin-store',
              name: 'admin.store',
              meta: {
                title: 'Powerbiz - Confirm Store',
                key: 'admin-store', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/store/admin-store'),
            },
            {
              path: ':id',
              component: () => import('@/views/store/[id].vue'),
              children: [
                {
                  path: 'product/synchronize',
                  name: 'store.product-synchronize',
                  meta: {
                    title: 'Powerbiz - ',
                    key: 'product.synchronize', // NOTE: please use key here. value based on key link side left menu list
                    authRequired: true,
                  },
                  component: () =>
                    import('@/views/store/[id]/product/synchronize/index.vue'),
                },
              ],
            },
          ],
        },

        // Channel
        // {
        //   path: '/channel',
        //   component: PassThrough,
        //   name: 'channel',
        //   children: [
        //     {
        //       path: '/channel/:id/:channelType/fulfillment',
        //       name: 'channel-fulfillment',
        //       meta: {
        //         title: 'Powerbiz - Channel',
        //         key: 'channel-fulfillment', // NOTE: please use key here. value based on key link side left menu list
        //         authRequired: true,
        //       },
        //       component: () => import('@/views/fulfillment/index'),
        //       children: [
        //         {
        //           path: '/channel/:id/:channelType/fulfillment/detail',
        //           name: 'fulfillment-detail',
        //           meta: {
        //             title: 'Powerbiz - fulfillment detail',
        //             key: 'channel-fulfillment-detail', // * NOTE: please use key here. value based on key link side left menu list
        //             authRequired: true,
        //           },
        //           component: () =>
        //             import('@/components/Fulfillment/Detail/index.vue'),
        //           children: [
        //             {
        //               path: '/channel/:id/:channelType/fulfillment/detail/update-order',
        //               name: 'fulfilment-update-order',
        //               meta: {
        //                 title: 'Powerbiz - fulfillment update order',
        //                 authRequired: true,
        //                 key: 'channel-fulfillment-detail-update-order', // * NOTE: please use key here. value based on key link side left menu list
        //               },
        //               component: () =>
        //                 import('@/components/Fulfillment/UpdateOrder'),
        //             },
        //             {
        //               path: '/channel/:id/:channelType/fulfillment/detail/check-stok-inventory/:catalogId',
        //               name: 'fulfillment-check-stock-inventory',
        //               meta: {
        //                 title: 'Powerbiz - fulfillment check stock inventory',
        //                 key: 'channel-fulfillment-stock-inventory',
        //                 authRequired: true,
        //               },
        //               component: () =>
        //                 import('@/components/Fulfillment/CheckStockInventory'),
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //     {
        //       path: '/channel/:id/:channelType/label',
        //       name: 'channel-fulfillment-label',
        //       meta: {
        //         title: 'Powerbiz - fulfillment label',
        //         key: 'channel-fulfillment-label', // * NOTE: please use key here. value based on key link side left menu list
        //         authRequired: true,
        //       },
        //       component: () =>
        //         import('@/components/Fulfillment/PrintLabel/index.vue'),
        //     },
        //   ],
        // },

        // Integration
        {
          path: '/integration',
          component: PassThrough,
          name: 'integrations',
          children: [
            {
              path: '/integration',
              name: 'integration.list',
              meta: {
                title: 'Powerbiz - Integration',
                key: 'integration', // NOTE: please use key here. value based on key link side left menu list
              },
              component: () => import('@/views/integration/list/index.vue'),
            },
            {
              path: '/integration/list',
              name: 'integration.list.channel',
              meta: {
                title: 'Powerbiz - Integration List Channel',
                key: 'integration-list-channel',
              },
              component: () => import('@/views/integration/list'),
            },
          ],
        },

        // Business
        // {
        //   path: '/business',
        //   component: PassThrough,
        //   name: 'business',
        //   children: [
        //     {
        //       path: '/business',
        //       name: 'business-client',
        //       meta: {
        //         title: `Powerbiz - ${i18n.t('sideBar.business')}`,
        //         key: 'business', // NOTE: please use key here. value based on key link side left menu list
        //         authRequired: true,
        //       },
        //       component: () =>
        //         import('@/views/setting/businessClient/index.vue'),
        //     },
        //     {
        //       path: '/business/add-client',
        //       name: 'add-client',
        //       meta: {
        //         title: `Powerbiz - ${i18n.t('sideBar.business')}`,
        //         key: 'add-client',
        //         authRequired: true,
        //       },
        //       component: () => import('@/views/setting/businessClient/add.vue'),
        //     },
        //     {
        //       path: '/business/edit-client',
        //       name: 'edit-client',
        //       meta: {
        //         title: `Powerbiz - ${i18n.t('sideBar.business')}`,
        //         key: 'edit-client',
        //         authRequired: true,
        //       },
        //       component: () => import('@/views/setting/businessClient/add.vue'),
        //     },
        //     {
        //       path: '/business/detail-client',
        //       name: 'detail-client',
        //       meta: {
        //         title: `Powerbiz - ${i18n.t('sideBar.business')}`,
        //         key: 'detail-client',
        //         authRequired: true,
        //       },
        //       component: () =>
        //         import('@/views/setting/businessClient/detail.vue'),
        //     },
        //   ],
        // },

        // Warehouse
        {
          path: '/warehouse',
          component: PassThrough,
          name: 'warehouse',
          children: [
            {
              path: '/warehouse',
              name: 'warehouse-list',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.warehouse')}`,
                key: 'warehouse', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/setting/warehouse/index.vue'),
            },
            {
              path: '/warehouse/add-warehouse',
              name: 'warehouse-add',
              meta: {
                title: `Powerbiz - ${i18n.t('warehouse.add_warehouse')}`,
                key: 'warehouse', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/setting/warehouse/add.vue'),
            },
            {
              path: '/warehouse/update-warehouse/:id',
              name: 'warehouse-update',
              meta: {
                title: `Powerbiz - ${i18n.t('warehouse.update_warehouse')}`,
                key: 'warehouse', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/setting/warehouse/add.vue'),
            },
          ],
        },

        // account-setting
        {
          path: '/account-setting',
          component: PassThrough,
          name: 'account-setting',
          children: [
            {
              path: '/account-setting',
              name: 'account-setting-list',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.account')}`,
                key: 'account-setting', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () =>
                import('@/views/setting/accountSetting/index.vue'),
            },
          ],
        },

        // export history
        {
          path: '',
          component: PassThrough,
          name: 'Export History',
          children: [
            {
              path: '/export-history',
              name: 'Export Histories',
              meta: {
                title: `Powerbiz - ${i18n.t('sideBar.export_history')}`,
                key: 'export-history', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/export/index.vue'),
            },
          ],
        },

        // Redeem
        {
          path: '/redeem-channel',
          component: PassThrough,
          name: 'redeem-channel:id',
          children: [
            {
              path: ':id/redeem-product-channel',
              name: 'redeem-channel.redeem-product-channel',
              meta: {
                title: 'Powerbiz - Redeem Product',
                key: 'redeem-product-channel-:id', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/redeem-channel/product.vue'),
            },
            {
              path: ':id/redeem-product-channel-edit',
              name: 'redeem-channel.redeem-product-channel-edit',
              meta: {
                title: 'Powerbiz - Redeem Product',
                key: 'redeem-product-channel-:id', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/redeem-channel/edit.vue'),
            },
            {
              path: ':id/redeem-product-channel/create',
              name: 'redeem-channel.create-new-redeem-product-channel',
              meta: {
                title: 'Powerbiz - Add Product Redeem',
                key: 'create-new-redeem-product-channel-:id',
                authRequired: true,
              },
              component: () => import('@/views/products/create'),
            },
            {
              path: ':id/redeem-product-channel/edit',
              name: 'redeem-channel.edit-new-redeem-product-channel',
              meta: {
                title: 'Powerbiz - Edit Product Redeem',
                key: 'edit-new-redeem-product-channel-:id',
              },
              component: () => import('@/views/products/edit'),
            },
            {
              path: ':id/redeem-sales-channel',
              name: 'redeem-channel.redeem-sales-channel',
              meta: {
                title: 'Powerbiz - Redeem Sales',
                key: 'redeem-sales-channel-:id', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => {
                return import('@/views/sales/index.vue')
              },
            },
          ],
        },

        {
          path: '/redeem-product-channel',
          component: PassThrough,
          name: 'redeem-product-channel:id',
          children: [
            {
              path: ':id/redeem-product-list',
              name: 'redeem-product-channel.redeem-product-list',
              meta: {
                title: 'Powerbiz - Redeem Product',
                key: 'redeem-product-list-:id', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/redeem-channel/product.vue'),
            },
          ],
        },

        // Distributor
        {
          path: '/distributor-channel',
          component: PassThrough,
          name: 'distributor-channel:id',
          children: [
            {
              path: ':id/distributor-list',
              name: 'distributor-channel.distributor-list',
              meta: {
                title: 'Powerbiz - Distributor',
                key: 'distributor-list-:id', // NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () =>
                import('@/views/distributor-channel/ListDistributor'),
            },
            {
              path: ':id/add-distributor',
              name: 'distributor-channel.add-distributor',
              meta: {
                title: 'Powerbiz - Add Distributor',
                key: 'distributor-list-:id',
                authRequired: true,
              },
              component: () =>
                import('@/views/distributor-channel/AddDistributor'),
            },
          ],
        },

        // Retur
        {
          path: '/retur-channel',
          component: PassThrough,
          name: 'retur-channel',
          children: [
            {
              path: ':id/submission',
              name: 'retur-channel.submission',
              meta: {
                title: `Powerbiz - Submission`,
                key: 'retur-submission-:id', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () =>
                import('@/views/retur-channel/submission/index.vue'),
            },
            {
              path: ':id/retur-list',
              name: 'retur-channel.retur-list',
              meta: {
                title: `Powerbiz - Retur`,
                key: 'retur-list-:id', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/retur-channel/list/index.vue'),
            },
            {
              path: ':id/retur-request-list',
              name: 'retur-channel.retur-request-list',
              meta: {
                title: `Powerbiz - Retur`,
                key: 'retur-request-list-:id', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/retur-channel/list/index.vue'),
            },
          ],
        },

        // Web Management
        {
          path: '/web-management',
          component: PassThrough,
          name: 'web-management',
          children: [
            {
              path: ':id/web-store-setting',
              name: 'web-management.web-store-setting',
              meta: {
                title: `Powerbiz - Banner`,
                key: 'web-store-setting-:id', // * NOTE: please use key here. value based on key link side left menu list
                authRequired: true,
              },
              component: () => import('@/views/web-platform-channel/store-settings'),
            },
          ],
        },

        // All Errors
        {
          path: '/error',
          component: PassThrough,
          name: 'error-page',
          children: [
            {
              path: '404',
              name: 'error404',
              meta: {
                title: 'Powerbiz - Error 404',
                authRequired: true,
              },
              component: () => import('@/views/errors/404.vue'),
            },
            {
              path: '403',
              name: 'error403',
              meta: {
                title: 'Powerbiz - Error 403',
                authRequired: true,
              },
              component: () => import('@/views/errors/403.vue'),
            },
          ],
        },
      ],
    },

    // Auth Pages
    {
      path: '/auth',
      meta: {
        title: 'Powerbiz - Powerbiz',
      },
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          meta: {
            title: 'Powerbiz - Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/403',
          meta: {
            title: 'Powerbiz - Error 403',
          },
          component: () => import('./views/auth/403'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Powerbiz - Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/register/link-expired',
          meta: {
            title: 'Powerbiz - Link Expired',
          },
          component: () => import('@/views/auth/link-expired'),
        },
        {
          name: 'LoginPage',
          path: '/auth/login',
          meta: {
            title: 'Powerbiz - Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Powerbiz - Lupa Kata Sandi',
            authRequired: false,
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/auth/forgot-password/otp',
          meta: {
            title: 'Powerbiz - Lupa Kata Sandi',
          },
          component: () => import('@/views/auth/forgot-password-otp'),
        },
        {
          path: '/auth/forgot-password/reset-password',
          meta: {
            title: 'Powerbiz - Reset Password',
          },
          component: () => import('@/views/auth/reset-password'),
        },
        {
          path: '/auth/forgot-password/success',
          meta: {
            title: 'Powerbiz - Reset Password',
            authRequired: false,
          },
          component: () =>
            import(
              '@/components/cleanui/system/Auth/ConfirmationSuccessForgotPassword'
            ),
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Powerbiz - Register',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/reg-conf/phone-number',
          meta: {
            title: 'Powerbiz - Register Confirmation',
          },
          component: () => import('./views/auth/reg-conf-phone-number'),
        },
        {
          path: '/auth/reg-conf/email',
          meta: {
            title: 'Powerbiz - Register Confirmation',
          },
          component: () => import('@/views/auth/reg-conf-email'),
        },
        {
          path: '/auth/reg-conf/otp',
          meta: {
            title: 'Powerbiz - Register Confirmation',
          },
          component: () => import('@/views/auth/reg-conf-otp'),
        },
        {
          path: '/auth/register/success',
          meta: {
            title: 'Powerbiz - Registration Success',
            authRequired: false,
          },
          component: () => import('@/views/auth/success-register'),
        },
        {
          path: '/auth/verify/:link/:uuid',
          component: () => import('@/views/auth/verify-email'),
        },
        {
          path: '/auth/confirmation/registered/invite-user/:uuid',
          meta: {
            title: 'Powerbiz - Registration Confirmation',
            // authRequired: true,
          },
          component: () => import('@/views/auth/confirmation-registered'),
        },
        {
          path: '/auth/confirmation/registered/invite-owner/:uuid',
          meta: {
            title: 'Powerbiz - Registration Confirmation',
            // authRequired: true,
          },
          component: () => import('@/views/auth/confirmation-registered'),
        },
        {
          path: '/auth/confirmation/invite-user/:uuid',
          meta: {
            title: 'Powerbiz - User Confirm Invitation',
            // authRequired: true,
          },
          component: () => import('@/views/auth/confirm-invite-user'),
        },
        {
          path: '/auth/confirmation/invite-owner/:uuid',
          meta: {
            title: 'Powerbiz - User Confirm Invitation',
            // authRequired: true,
          },
          component: () => import('@/views/auth/confirm-invite-user'),
        },
        {
          path: '/auth/confirmation/invite-owner-registered/:uuid',
          meta: {
            title: 'Powerbiz - Owner Confirm Invitation',
            // authRequired: true,
          },
          component: () => import('@/views/auth/confirm-invite-user'),
          redirect: () => {
              return '/'
          },
        },
        {
          path: '/auth/confirmation/invite-user-registered/:uuid',
          meta: {
            title: 'Powerbiz - User Confirm Invitation',
            // authRequired: true,
          },
          component: () => import('@/views/auth/confirm-invite-user'),
          redirect: () => {
              return '/'
          },
        },
        {
          path: '/auth/user-not-found',
          meta: {
            title: 'Powerbiz - User Not Found',
            authRequired: false,
          },
          component: () => import('@/views/auth/user-not-found'),
        },
      ],
    },
    //confirm
    {
      path: '/confirmation',
      meta: {
        title: 'Powerbiz - Powerbiz',
      },
      component: AuthLayout,
      children: [
        {
          path: '/confirmation/registered/success',
          meta: {
            title: 'Powerbiz - Registration Success',
          },
          component: () => import('@/views/auth/success-register'),
        },
        {
          path: '/confirmation/registered/timeout',
          meta: {
            title: 'Powerbiz - Timeout',
          },
          component: () => import('@/views/auth/register-confirmation-timeout'),
        },
      ],
    },
    {
      path: '/fulfillment',
      meta: {
        title: 'Powerbiz - Full View Label',
      },
      component: PublicLayout,
      children: [
        {
          path: '/fulfillment/labelfullpage',
          name: 'fulfillment-labelfullpage',
          meta: {
            title: `Powerbiz - ${i18n.t('sideBar.fulfillment')}`,
            key: 'fulfillment-labelfullpage', // * NOTE: please use key here. value based on key link side left menu list
          },
          component: () =>
            import('@/components/Fulfillment/FullViewLabel/index.vue'),
        },
        {
          path: '/fulfillment/bulk-shipping-label',
          name: 'fulfillment-shipping-label',
          meta: {
            title: `Powerbiz - ${i18n.t('sideBar.fulfillment')}`,
            key: 'fulfillment-bulk-shipping-label', // * NOTE: please use key here. value based on key link side left menu list
            authRequired: true,
          },
          component: () =>
            import('@/components/Fulfillment/BulkPrintLabel/index.vue'),
        },
      ],
    },
    {
      path: '/self-register',
      component: () => import('./views/auth/self-register'),
    },
    // Uncomment later
    // {
    //   path: '/warehouse-register',
    //   component: () => import('./views/auth/warehouse-register'),
    // },

    // Redirect to 404

    {
      path: '*',
      redirect: 'auth/404',
      hidden: true,
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  /**
   * TODO: cretae condition for cek token. this code hide just for setup layout in register flow
   */
  // if (!store.state.app.id && to.path !== '/auth/404' && !store.state.app.fetched) {
  //   return next('/auth/404')
  // }
  if (to.matched.some(record => record.meta && record.meta.authRequired)) {
    const exp = store.getters['user/expireIn']

    if (exp < new Date().getTime() / 1000) {
      store.commit('user/LOGOUT')
    }
    if (!store.getters['user/authorized']) {
      {
        return next({
          path: '/auth/login',
          query: { redirect: to.fullPath },
        })
      }
    }
  }

  if (to.path === '/') {
    return next({
      path: '/dashboard',
    })
  }

  next()
})

export default router
